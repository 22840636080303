@import "_home.scss";

.internas{
	padding-bottom:36px;

	form{

		.form-group,
		.input-group{

			select.form-control{
				background:url('../images/bg-custom-select.png') no-repeat calc(100% - 15px) center #EEEEEE;
				-webkit-appearance:none;
			}

		}

	}

	hr.hr-internas{
		border-bottom:2px solid #dbdbdb;
	}

	.card-noticias{
		margin-bottom:48px;
	}

	.menu-toggle{
		list-style-type:none;
		margin-bottom:42px;
		padding-left:0;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		align-items:center;
		max-width:916px;
		margin-left:auto;
		margin-right:auto;

		@include media-breakpoint-down(md){
			justify-content:center;
		}

		li{
			margin-bottom:5px;
		}

	}

	.breadcrumb-internas{
		list-style-type:none;
		margin-bottom:45px;
		padding-left:0;
		background-color:#E10415;
		padding:13px 0;
		color:#FFF;
		font-family: 'ubuntubold';
		text-align:right;
		display:flex;
		flex-wrap:wrap;
		justify-content:flex-end;
		align-items:center;
		font-size:16px;
		position:relative;

		li{

			& + li{

				&:before{
					content:"/";
					margin:0 5px;
				}

			}

		}

		@include beforeAfter{
			content:"";
			display:block;
			width:100vw;
			height:100%;
			background-color:inherit;
			position:absolute;
			top:0;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

	}

	.figure__planos{
		text-align:center;
		margin-bottom:25px;

		img{
			border-radius:100%;
			border:3px solid $theme;
			transition:border-color 0.6s linear;
		}

		&:hover{

			img{
				border-color:transparent;
			}

		}

	}

}