.btn{
	border-radius:30px;
	min-width:162px;
	font-size:16px;
	font-family: 'ubuntubold';
	padding:7px 12px;

	&.btn--theme-2-to-red{
		@include button-variant($theme2,$theme2);
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten(#E10415,10%),lighten(#E10415,10%));
			color:#FFF;
		}

	}

	&.btn--theme-2-to-theme{
		@include button-variant($theme2,$theme2);
		color:#FFF;

		&:hover,
		&:focus,
		&.active{
			@include button-variant(lighten($theme,5%),lighten($theme,5%));
			color:#FFF;
		}

	}

	&.btn--red{
		@include button-variant(darken(#E10415,5%),darken(#E10415,5%));
		color:#FFF;
	}

	&.btn--pill{
		border-radius: 2rem;
	}

	&.btn--min-width-178{
		min-width:178px;
	}

	&.btn--min-width-200{
		min-width:200px;
	}

	&.btn--responsivo{
		min-width:inherit;
		box-shadow:none;

		.bar{
			width:32px;
			height:5px;
			display:inline-block;
			position:relative;
			background-color:$theme;

			&,
			&:before,
			&:after{
				transition:all 0.6s linear;
			}

			@include beforeAfter{
				content:"";
				display:block;
				width:100%;
				height:100%;
				background-color:inherit;
				position:absolute;
				left:0;
			}

			&:before{
				top:calc(100% + 4px);
			}

			&:after{
				bottom:calc(100% + 4px);
			}

		}

	}

}