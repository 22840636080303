.media-incrivel{
	font-size:14px;

	@include media-breakpoint-up(sm){
		display:flex;
		align-items:center;
		flex-wrap:wrap;
	}

	.media-incrivel__box-img{

		@include media-breakpoint-up(sm){
			margin-right:10px;
		}

	}

	.media-incrivel__box-desc{
		color:$theme;
		font-family: 'ubuntubold';
		line-height:1.2;

		a{
			display:block;

			.icon{
				vertical-align:text-bottom;

				@include media-breakpoint-up(lg){
					margin-left:2px;
				}

			}

		}

	}

	& + .media-incrivel{

		@include media-breakpoint-up(lg){
			position:relative;

			&:before{
				content:'';
				display:block;
				width:1px;
				height:100%;
				background-color:#EDEDED;
				position:absolute;
				top:0;
				right:calc(100% + 20px);
			}

		}

	}

}