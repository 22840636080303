.form-cotacao{
	
	form{
		max-width:736px;
		margin-left:auto;

		@include media-breakpoint-down(md){
			margin-right:auto;
		}

		.input-group,
		.form-group{
			margin-bottom:6px;

			.form-control{
				resize:none;
				min-height:40px;
				@include place(#FFF);
				background-color:#010E5D;
				border-color:#010E5D;
				@include sombra(darken(#010E5D,10%));
				border-radius:30px;
				padding-left:18px;
				font-size:16px;
			}

			textarea.form-control{
				padding-top:14px;
				border-radius:22px;
				height:auto;
				min-height:87px;
			}

			&.form-group-textarea{
				flex-grow:1;
			}

		}

	}

	&.form-col-acompanha-textarea{

		form{

			@include media-breakpoint-up(lg){
				
				.col-textarea{
					display:flex;
					flex-wrap:wrap;
					flex-direction:column;
					justify-content:flex-end;
				}

			}

		}

	}

}

.form-sem-label{

	form{

		.form-group,
		.input-group{

			label:not(.label-on){
				@include sr-only();
			}

		}

	}

}

.form-contato{

	form{

		.form-group,
		.input-group{

			.form-control{
				border-radius:30px;
				border-color:$theme;
				resize:none;
				@include place($theme);
				@include sombra($theme);
			}

			textarea.form-control{
				border-radius:18px;
				padding-top:14px;
			}

		}

	}

}

.form-internas{

	form{

		.form-group,
		.input-group{
			margin-bottom:10px;

			.form-control{
				min-height:48px;
				resize:none;
				padding-left:18px;
				background-color:#EEEEEE;
				border-color:#EEEEEE;
				@include sombra(#EEEEEE);
				font-size:14px;
				@include place(#9d9d9d);
				border-radius:0;
			}

			textarea.form-control{
				padding-top:14px;
			}

		}

		legend{
			font-size:15px;
			color:$theme;
			text-align:left;
			font-family: 'ubuntubold';
			margin-bottom:5px;
		}

	}

}

.pesquisas-topo{
	font-family: 'ubuntuitalic';

	@include media-breakpoint-down(md){
		padding-top:10px;
		padding-bottom:10px;
		margin-bottom:15px;
		background-color:darken($theme,10%);

		form{

			.input-group{
				text-align: center;

				.input-group-prepend{
					justify-content:center;
				}

			}

		}

	}

	@include media-breakpoint-up(lg){

		position:relative;

		@include beforeAfter{
			content:"";
			position:absolute;
			width:1px;
			height:100%;
			background-color:darken($theme,10%);
			top:50%;
			height:28px;
			transform:translateY(-50%);
		}

		&:before{
			left:-15px;
		}

		&:after{
			left:calc(100% + 15px);
		}

	}
	
	form{

		.input-group,
		.form-group{

			.input-group-prepend{
				margin-right:0;
			}

			.form-control{
				background-color:transparent;
				border:none;
				@include place(#FFF);
				font-style:italic;
				font-size:16px;
				height:auto;
				min-height:40px;
				box-shadow:none;

				&:focus{
					@include place(darken(#FFF,10%));
				}

				@include media-breakpoint-up(lg){
					min-width:300px;
				}

			}

			.btn{
				min-width:inherit;
				box-shadow:none;
				line-height:1;
			}

		}

	}

}