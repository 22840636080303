.banner{
	text-align:center;

	.carousel-item{
		margin-bottom:0;
	}

	.controls{
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		align-items:flex-end;
		justify-content:center;
		padding-bottom:4px;
		padding-top:8px;
		padding-bottom:8px;

		@include media-breakpoint-up(lg){
			padding-top:0;
			padding-bottom:28px;
			position:absolute;
			top:0;
			left:0;
		}

		.clearfix{
			width:100%;

			& > *{
				z-index:900;
				position:relative;
			}

		}

	}

}

.cotacao{
	padding-top:46px;
	padding-bottom:3px;
	background-color:$theme;
	color:#FFF;
	background-size:cover;
	background-position:center;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.container > .row > *{
		margin-bottom:48px;
	}

}

.planos{
	padding-top:32px;
	text-align:center;
	padding-bottom:42px;
}

.protecao{
	background-color:#F7F7F7;
	padding-top:46px;
	padding-bottom:40px;

	@include media-breakpoint-down(md){
		text-align:center;
	}

}

.noticias{
	text-align:center;
	padding-top:46px;
	padding-bottom:50px;
}

.contato{
	padding-top:36px;
	padding-bottom:12px;
	background-color:#d58500;
	color:#FFF;
	background-attachment:fixed;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.container > .row > *{
		margin-bottom:24px;
	}

}

.segura-carousel{
	position:relative;

	.segura-carousel__controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		padding-bottom:17px; // metade do height das flechas controladores do carousel

		.clearfix{
			width:100%;
		}

	}

}