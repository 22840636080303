.box-quem-somos{
	background-color:#F7F7F7;

	@include media-breakpoint-up(lg){
		display:flex;
	}

	@include media-breakpoint-down(md){
		text-align:center;
	}

	& > *{
		min-height:430px;
	}

	.box-quem-somos__img{
		background-repeat:no-repeat;
		background-size:cover;
		width:100%;
		background-position:center center;

		@include media-breakpoint-up(lg){
			width:(1044 / 1920) * 100%;
		}

	}

	.box-quem-somos__text{
		padding-top:46px;
		padding-bottom:23px;

		@include media-breakpoint-down(md){
			@include make-container();
			@include make-container-max-widths();
		}

		@include media-breakpoint-up(lg){
			width:(876 / 1920) * 100%;
			padding-left:26px;
		}

		.box-quem-somos__desc{

			@include media-breakpoint-up(lg){
				max-width:474px;
				margin-right:auto;
			}

			.box-quem-somos__title{
				font-size:27px;
				color:$theme;
				font-family: 'ubuntubold';
				margin-bottom:30px;
			}

			.box-quem-somos__commit{
				margin-bottom:32px;
				font-size:16px;
				line-height:1.3;
				max-height:160px;
				overflow:hidden;
			}

		}

	}

}