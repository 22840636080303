.table-planos{
	margin-bottom:40px;
	font-family: 'ubuntubold';
	overflow:hidden;

	.table-planos__header{

		.table-planos__title{
			font-size:18px;
			color:#FFF;
			background-color:$theme;
			padding:12px 20px;
			line-height:1;
		}

	}

	.table-planos__body{
		font-size:15px;
		color:$theme;
		background-color:#E8E8E8;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		border-radius: 0 0 6px 6px;
		overflow:hidden;

		.table-planos__body-columns-left{

		}

		.table-planos__body-columns,
		.table-planos__body-columns-left{
			display:flex;
			flex-wrap:wrap;
			justify-content:flex-end;
			position:relative;
			z-index:50;

			@include media-breakpoint-down(lg){
				width:100%;
			}

		}

		.table-planos__body-columns{

			@include media-breakpoint-up(xl){
				min-width:736px;
			}

			.table-planos__column{
				flex-grow:1;
			}

		}

		.table-planos__column{
			background-colo:#EDEDED;
			width:100%;

			@include media-breakpoint-up(xl){
				width:auto;
				max-width:184px;
			}

			.table-planos__column-header{
				color:#FFF;
				padding:10px 15px;
				background-color:$theme2;
				word-break:normal;
				min-height:60px;
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:flex-start;

				& > *{
					line-height:1;
					width:100%;
				}

				.table-planos__column-title{
					font-size:18px;
				}

				.table-planos__column-subtitle{
					padding-top:7px;
					font-size:15px;
				}

			}

			.table-planos__column-body{

				.table-planos__column-box{
					padding:7px 14px;
					min-height:38px;
					word-break:normal;
					position:relative;

					&,
					&:before{
						background-color:#EDEDED;
					}

				}

				.table-planos__column-box:nth-child(odd){

					&,
					&:before{
						background-color:#E8E8E8;
					}

				}

			}

			& + .table-planos__column:nth-child(even):not(.table-planos-column-left){

				.table-planos__column-header{
					background-color:#BF0B1B;
					box-shadow:0 0 8px rgba(#000,0.5);
				}

				.table-planos__column-body{

					.table-planos__column-box{
						background-color:darken(#EDEDED,5%);
					}

					.table-planos__column-box:nth-child(odd){
						background-color:darken(#E8E8E8,5%);
					}

				}

			}

			&.table-planos-column-left{
				flex-grow:1;
				position:relative;
				z-index:49;
				min-width:220px;

				.table-planos__column-principal{
					min-height:60px;
					padding:10px 15px;
					font-size:18px;
					color:#BF0B1B;
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					position:relative;
					justify-content:flex-start;

					@include media-breakpoint-down(lg){
						justify-content:center;
					}

					&,
					&:before{
						background-color:#EDEDED;
					}

				}

				.table-planos__column-box{
					
					@include media-breakpoint-up(xl){
						text-align:left;
					}

				}

				.table-planos__column-principal:before,
				.table-planos__column-body .table-planos__column-box:before{
					
					@include media-breakpoint-up(xl){
						content:"";
						display:block;
						width:100vw;
						height:100%;
						position:absolute;
						top:0;
						left:100%;
					}

				}

			}

		}

	}

	&.table-planos-credenciados{

		.table-planos__body{

			.table-planos__body-columns{

				@include media-breakpoint-up(xl){
					min-width:500px;
				}

			}

			.table-planos__column{

				&:first-child:not(.table-planos-column-left){

					.table-planos__column-header:first-child{
						background-color:#BF0B1B !important;
						box-shadow:0 0 8px rgba(#000,0.5) !important;
					}

					.table-planos__column-body{

						.table-planos__column-box{
							background-color:darken(#EDEDED,5%);
						}

						.table-planos__column-box:nth-child(odd){
							background-color:darken(#E8E8E8,5%);
						}

					}

				}

				& + .table-planos__column:nth-child(even):not(.table-planos-column-left){

					.table-planos__column-header{
						background-color:$theme2;
						box-shadow:none;
					}

					.table-planos__column-body{

						.table-planos__column-box{

							&,
							&:before{
								background-color:#EDEDED;
							}

						}

						.table-planos__column-box:nth-child(odd){

							&,
							&:before{
								background-color:#E8E8E8;
							}

						}

					}


				}

				& + .table-planos__column:nth-child(odd):not(.table-planos-column-left){

					.table-planos__column-header{
						background-color:#BF0B1B !important;
						box-shadow:0 0 8px rgba(#000,0.5) !important;
					}

					.table-planos__column-body{

						.table-planos__column-box{
							background-color:darken(#EDEDED,5%);
						}

						.table-planos__column-box:nth-child(odd){
							background-color:darken(#E8E8E8,5%);
						}

					}

				}

				&:first-child{

					.table-planos__column-principal{
						background-color:#DCDCDC;
					}

					.table-planos__column-body{

						.table-planos__column-box{
							background-color:darken(#EDEDED,5%);
						}

						.table-planos__column-box:nth-child(odd){
							background-color:darken(#E8E8E8,5%);
						}

					}

				}

			}

		}

	}

}