.card-planos{
	max-width:254px;
	margin-left:auto;
	margin-right:auto;
	background-color:#F7F7F7;
	padding:0 19px 20px 19px;
	position:relative;
	margin-bottom:38px;

	&:before{
		content:"";
		display:block;
		background-color:#FFF;
		width:100%;
		height:51px;
		position:absolute;
		top:0;
		left:0;
	}

	.card-planos__figcaption,
	.card-planos__planos{
		color:#A9A9A9;
	}
	
	.card-planos__figure{
		margin-bottom:8px;
		border-bottom:1px solid #ACACAC;

		img{
			border-radius:100%;
			border:3px solid $theme;
			transition:border-color 0.6s linear;
		}

		.card-planos__figcaption{
			padding-top:8px;
			padding-bottom:6px;
			font-size:16px;
		}

	}

	.card-planos__content{

		.card-planos__menu{
			list-style-type:none;
			margin-bottom:10px;
			padding-left:0;
			color:$theme;
			font-family: 'ubuntubold';
			border-bottom:1px solid #ACACAC;

			li{
				margin-bottom:4px;
			}

		}

		.card-planos__planos{
			margin-bottom:4px;
		}

		.card-planos__valor{
			color:$theme2;
			font-size:15px;
			font-family: 'ubuntubold';
			line-height:1;
			display:flex;
			flex-wrap:wrap;
			justify-content:center;
			margin-bottom:8px;

			span{
				font-size:30px;
				display:inline-block;
				padding-left:6px;
				padding-right:3px;
			}

		}

	}

	&:hover{

		.card-planos__figure{

			img{
				border-color:transparent;
			}

		}

	}

}