.header-section{
	font-size:30px;

	& > *{
		line-height:1;
	}

	.header-section__title{
		font-size:1em;
		font-family: 'ubuntubold';
		margin-bottom:12px;
	}

	.header-section__subtitle{
		font-size:(16 / 30) * 1em;
	}

}