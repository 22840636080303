.card-protecao{
	min-height:10px;
	text-align:left;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	& > *{
		margin-bottom:40px;
	}

	@include media-breakpoint-up(md){
		display:flex;
		flex-wrap:wrap;
	}

	.card-protecao__figure{
		width:90px;
		height:90px;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		text-align:center;
		border:3px solid $theme;
		border-radius:100%;
		position:relative;

		@include media-breakpoint-down(md){
			text-align:center;
			margin-right:auto;
			margin-left:auto;
		}


		icon-awesome{
			display:block;
			width:40px;
			height:40px;
			margin-left:auto;
			margin-right:auto;
			position:relative;

			.icon-awesome{
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-color:$theme;
				transition:background-color 0.6s linear;
			}

		}

		&:before{
			content:"";
			display:block;
			width:70px;
			height:70px;
			background-color:transparent;
			transition:background-color 0.6s linear;
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			border-radius:100%;
		}

	}

	.card-protecao__content{
		font-size:15px;
		line-height:1.2;

		@include media-breakpoint-up(md){
			max-width:244px;
			padding-left:12px;
		}

		.card-protecao__title{
			font-size:18px;
			margin-bottom:14px;
			line-height:1;
			color:$theme;
			font-family: 'ubuntubold';
		}

	}

	&:hover{

		.card-protecao__figure{

			&:before{
				background-color:$theme;
			}

			icon-awesome{

				.icon-awesome{
					background-color:#FFF;
				}

			}

		}

	}

}