@import "_fontfaces";

body{
	font-size:16px;
	color:#A9A9A9;
	font-family: 'ubunturegular';

	&.menu-active{

		.btn--responsivo{

			.bar{
				transform:rotate(45deg);

				&:before{
					transform:rotate(-90deg);
					top:0;
				}

				&:after{
					bottom:0;
					transform:rotate(-90deg);
				}

			}

		}

		.topo{

			.nav-content{
				left:0;
			}

		}

	}

}

.bg-menu{
	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:rgba(#000,0.5);
	z-index:800;
}

.max-width{
	margin-left:auto;
	margin-right:auto;

	&.max--790{
		max-width:790px;
	}

}

#app{
	position:relative;
	overflow:hidden;
}

.topo a,
.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

	.slick-track{

		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

}

.segura-carousel{

	.slick-slider{
		z-index:50;
	}

	.clearfix{

		& > *{
			z-index:400;
			position:relative;
		}

	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

.slick-slider{

	.slick-track{
		text-align:center;
		
		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

	img{
		@include img-fluid();
	}

}

// FONTS
.font--italic{
	font-style:italic;
}

.font--fnt-14{
	font-size:14px;
}

// concerta erro meu (João) de sprites em outros projetos
// .icon{

// 	@each $icon,$value in $icons {
// 		&.#{$icon} {
// 			width: nth($value,3);
// 			height: nth($value,4);
// 			background-position: #{nth($value,1)} #{nth($value,2)};
// 		}
// 	}
// }

.line--height-1{
	line-height:1 !important;
}

.line--height-1-2{
	line-height:1.2 !important;
}

// FONTS USADAS
.font--ubu-med{
	font-family: 'ubuntumedium';
}

.font--ubu-reg{
	font-family: 'ubunturegular';
}

.font--ubu-bld{
	font-family: 'ubuntubold';
}

.font--ubu-itc{
	font-family: 'ubuntuitalic';
}

.text--theme{
	color: $theme;
}

.text--theme-2{
	color: $theme2;
}

.text--danger{
	color: #c00c1b;
}

.text--gray{
	color:#9D9D9D !important;
}

.text--inherit{
	color:inherit !important;
}

.font--fnt-28{
	font-size:28px !important;
}

.circle-arrow{
	@extend .fas;
	font-size:16px;
	width:(35 / 16) * 1em;
	height:(35 / 16) * 1em;
	line-height:(35 / 16) * 1em;
	background-color:$theme;
	color:#FFF;
	border-radius:100%;
	@include margin(0,11);
	text-align:center;

	@include hover-focus{
		background-color:$theme2;
		color:#EFEEDA;
	}

	&.circle-arrow--left{
		@extend .fa-chevron-left;
	}

	&.circle-arrow--right{
		@extend .fa-chevron-right;
	}

}

.text--theme{
	color:$theme !important;
}

.embed-responsive-1920by350{

	&:before{
		height:350px;
		padding-top:(350 / 1920) * 100%;
	}

}