.radio-custom{
	font-size:13px;
	margin-bottom:6px;

	& + .radio-custom{
		
		// @include media-breakpoint-up(xs){
		// 	margin-left:30px;
		// }

	}

	.nav-link{

		&.active{
			background-color:transparent;
		}

	}

	& > label{
		display:inline-block;
		position:relative;
		width:100%;
		height:100%;
	}

	span{
		position:relative;
		display:inline-block;
		width:1.7em;
		height:1.7em;
		border-radius:100%;
		border:5px solid transparent;
		vertical-align:bottom;
		background-color:transparent;
		margin-right:0.3em;
		transition:all 0.6s linear;
		font-size:12px;

		&:before{
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			border-radius:100%;
			transform:scale(1);
			background-color:transparent;
			border:2px solid transparent;
			transition:all 0.6s linear;
		}

	}

	&.radio-custom-grande{
		
		.circle-legal{
			font-size:18px;
			vertical-align:bottom;
		}

	}

	input{
		position:fixed;
		left:-200vw;

		&:checked ~ label > .circle-legal:before{
			transform:scale(1);
		}

	}

	&.radio-custom-padrao{
		font-family: 'ubunturegular';

		.circle-legal{
			background-color:#DDDDDD;
			border:2px solid transparent;
		}

		input{

			&:checked ~ label > .circle-legal{
				border-color:#7B7B7B;
				background-color:#7B7B7B;

				&:before{
					background-color:#7B7B7B;
					border-color:#7B7B7B;
				}

			}

		}

	}

}