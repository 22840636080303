.menu-topo{
	list-style-type:none;
	padding-left:0;
	font-family: 'ubuntumedium';
	margin-bottom:15px;

	@include media-breakpoint-up(lg){
		margin-bottom:0;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		max-width:604px;
		flex-grow:1;
	}

	li{

		a{
			display:block;

			@include media-breakpoint-up(lg){
				padding:8px 0;
			}

			@include media-breakpoint-down(md){
				padding:10px 15px;
			}

		}

		&.active,
		&:hover{

			@include media-breakpoint-up(lg){

				a{
					color:#d58500;
				}

			}

			@include media-breakpoint-down(md){
				background-color:#d58500;
				color:#FFF;
			}

		}

	}

}