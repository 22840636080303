.topo{

	.nav-content{

		@include media-breakpoint-up(lg){
			padding-top:7px;
			@include make-container();
			@include make-container-max-widths();
		}

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			left:-230px;
			transition:left 0.6s linear;
			width:230px;
			height:100%;
			background-color:#FFF;
			z-index:1000;
			border-right:1px solid darken($theme,10%);
			overflow:auto;
		}

		.bar-topo{

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				align-items:center;
			}

			.logo-desktop{

				@include media-breakpoint-down(md){
					padding:30px;
				}

			}

			& > *{
				margin-bottom:5px;
			}

			.bar-topo__info{

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					align-items:center;
					flex-grow:1;
					max-width:725px;
				}

				@include media-breakpoint-down(md){
					display:none;
				}

				& > *:first-child{

					@include media-breakpoint-up(xl){
						min-width:220px;
					}

				}

			}

		}

	}

	.bar-menu{
		background-color:$theme;
		color:#FFF;
		position:relative;
		display:flex;
		flex-wrap:wrap;
		flex-direction:column;

		@include media-breakpoint-up(lg){
			align-items:center;
			justify-content:space-between;
			flex-direction:row;

			@include beforeAfter{
				content:'';
				display:block;
				width:100vw;
				height:100%;
				position:absolute;
				top:0;
				background-color:inherit;
			}

			&:before{
				left:100%;
			}

			&:after{
				right:100%;
			}

		}

		.pesquisas-topo{

			@include media-breakpoint-down(md){
				order:1;
			}

		}

		.menu-topo{

			@include media-breakpoint-down(md){
				order:2;
			}

		}

		.bar-menu__redes-sociais{

			@include media-breakpoint-down(md){
				order:3;
			}

		}

	}

	&.fx{

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			z-index:700;
			animation:fadeInDown 0.6s linear;
		}

	}

}