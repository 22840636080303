.redes-sociais{

	& > *{
		@include margin(0,5);
		font-size:14px;
		width:(18 / 14) * 1em;
		height:(18 / 14) * 1em;
		line-height:(19 / 14) * 1em;
		background-color:#FFF;
		color:$theme;
		text-align:center;
		display:inline-block;
		border-radius:100%;

		@include hover-focus{
			background-color:darken($theme,10%);
			color:#FFF;
		}

	}

	&.bar-menu__redes-sociais{

		@include media-breakpoint-down(md){
			padding:0 15px 20px 15px;
			text-align:center;

			& > *{
				font-size:20px;
			}

		}

	}

}