.paginacao{
	margin-bottom:20px;

	& > *{
		font-size:16px;
		width:(41 / 16) * 1em;
		height:(41 / 16) * 1em;
		line-height:(41 / 16) * 1em;
		border-radius:100%;
		background-color:$theme2;
		color:#FFF;
		display:inline-flex;
		justify-content:center;
		align-items:center;
		flex-wrap:wrap;
		padding:0;
		@include margin(0,5);
		transition:all 0.6s linear;

		@include hover-focus{
			background-color:$theme;
			color:#FFF;
		}

	}

	.fa-chevron-left{
		@extend .fa-minus;
	}

	.fa-chevron-right{
		@extend .fa-plus;
	}

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

}
