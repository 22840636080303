.card-seguro{
	max-width:350px;
	margin-left:auto;
	background-color:red;
	margin-right:auto;

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
	}

	.card-seguro__figure{
		min-height:90px;
		min-width:90px;
		border:3px solid $theme;
		border-radius:100%;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;
	}
	
	.card-seguro__content{

		@include media-breakpoint-up(lg){
			padding-left:8px;
		}

		.card-seguro__title{
			color:$theme;
			font-size:18px;
			font-family: 'ubuntubold';
			margin-bottom:12px;
		}

	}

}