.rodape{
	background-color:$theme;
	color:#FFF;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.rodape__whatsapp{
		position:fixed;
		bottom:15px;
		left:15px;
		z-index:600;
		transition:transform 0.6s linear;

		&:hover{
			animation:jello 1s linear;
		}

	}

	.rodape-info{
		padding-top:21px;
		font-family: 'ubuntubold';
		font-size:14px;

		span{
			color:#c74d16;
		}

		.logo-rodape{
			margin-bottom:0;
			padding-bottom:8px;
		}

		.container > .row > *{
			margin-bottom:24px;
		}

	}

	.link{

		& + .link{

			&:before{
				content:"|";
				margin:0 3px;
			}

		}

	}

	.rodape-copyright{
		border-top:1px solid #010E5C;
		font-size:11px;
		padding:7px 0;
		font-family: 'ubunturegular';

		.row > *{
			flex-grow:1;
		}

		.gv8{
			font-size:10px;
		}

		img{
			max-width:44px;
		}

	}

}
