.card-noticias{
	max-width:350px;
	margin-left:auto;
	margin-right:auto;
	border-radius:6px;
	overflow:hidden;
	margin-bottom:23px;
	box-shadow:0 0 0 rgba(#000,0.5);
	transition:box-shadow 0.6s linear;

	.card-noticias__figure{
		margin-bottom:0;
	}

	.card-noticias__content{
		background-color:#F7F7F7;
		padding:18px 8px 14px 8px;
		text-align:left;
		position:relative;

		.card-noticias__date,
		.card-noticias__title{
			font-family: 'ubuntubold';
		}

		.card-noticias__date{
			font-size:14px;
			color:#A9A9A9;
			margin-bottom:10px;
		}

		.card-noticias__title{
			margin-bottom:16px;
			font-size:18px;
			color:$theme;
			transition:color 0.6s linear;
		}

		.card-noticias__btn{
			position:absolute;
			top:0;
			right:13px;
			transform:translateY(-50%);
		}

	}

	&:hover{
		box-shadow:0 0 8px rgba(#000,0.5);

		.card-noticias__content{

			.card-noticias__title{
				color:$theme2;
			}

		}

	}

}