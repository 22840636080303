@font-face {
    font-family: 'ubuntumedium';
    src: url('#{$fonts}/Ubuntu-Medium/ubuntu-medium-webfont.eot');
    src: url('#{$fonts}/Ubuntu-Medium/ubuntu-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Ubuntu-Medium/ubuntu-medium-webfont.woff2') format('woff2'),
         url('#{$fonts}/Ubuntu-Medium/ubuntu-medium-webfont.woff') format('woff'),
         url('#{$fonts}/Ubuntu-Medium/ubuntu-medium-webfont.ttf') format('truetype'),
         url('#{$fonts}/Ubuntu-Medium/ubuntu-medium-webfont.svg#ubuntumedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ubunturegular';
    src: url('#{$fonts}/ubuntu_regular/ubuntu-regular-webfont.eot');
    src: url('#{$fonts}/ubuntu_regular/ubuntu-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/ubuntu_regular/ubuntu-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/ubuntu_regular/ubuntu-regular-webfont.woff') format('woff'),
         url('#{$fonts}/ubuntu_regular/ubuntu-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/ubuntu_regular/ubuntu-regular-webfont.svg#ubunturegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ubuntubold';
    src: url('#{$fonts}/ubuntu_bold/ubuntu-bold-webfont.eot');
    src: url('#{$fonts}/ubuntu_bold/ubuntu-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/ubuntu_bold/ubuntu-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/ubuntu_bold/ubuntu-bold-webfont.woff') format('woff'),
         url('#{$fonts}/ubuntu_bold/ubuntu-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/ubuntu_bold/ubuntu-bold-webfont.svg#ubuntubold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ubuntuitalic';
    src: url('#{$fonts}/Ubuntu-Italic/ubuntu-italic-webfont.eot');
    src: url('#{$fonts}/Ubuntu-Italic/ubuntu-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Ubuntu-Italic/ubuntu-italic-webfont.woff2') format('woff2'),
         url('#{$fonts}/Ubuntu-Italic/ubuntu-italic-webfont.woff') format('woff'),
         url('#{$fonts}/Ubuntu-Italic/ubuntu-italic-webfont.ttf') format('truetype'),
         url('#{$fonts}/Ubuntu-Italic/ubuntu-italic-webfont.svg#ubuntuitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}