// bg-position(x,y) width height
$icons: (
	'icon-check': -68px -67px 21px 21px,
	'icon-close': -67px -35px 22px 22px,
	'icon-gps': -102px 0px 20px 25px,
	'icon-phone': -67px 0px 25px 25px,
	'icon-search': -102px -66px 18px 18px,
	'icon-telphone': 0px -67px 24px 25px,
	'icon-whats-big': 0px 0px 57px 57px,
	'icon-whats-white': -34px -67px 24px 24px,
	'icon-whats': -102px -35px 20px 21px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 122px 92px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 122px 92px;

	display: inline-block;
	vertical-align: middle;

}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
